import React from 'react';
import {
    TextField,
    SelectField,
    Labeled,
    FunctionField,
    useGetOne,
} from 'react-admin';

import { Box, Divider, Grid } from '@material-ui/core';
import choices from '../choices';
import DetailedProducts from './DetailedProducts';
import { getPriceTypeLabel } from '../../../helpers/orderHelper';
import CopyProductInformation from './CopyProductInformation';
import ContactEmailField from './ContactEmailField';
import { INVOICE_TYPES } from '../constants';

const ContactPhoneField = (all) => (
    <Labeled label="Teléfono de contacto">
        <TextField source="buyerData.phone" {...all} />
    </Labeled>
);

const commomPropsContainer = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 0.2fr)',
    gap: 2,
};

const DetailsTab = ({ editable = false, record, ...rest }) => {
    const all = { record, ...rest };
    const { buyer } = record;
    const {
        invoiceType, dni, cuit,
    } = record.buyerData;
    const { data: user } = useGetOne('users', buyer);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} {...commomPropsContainer} container>
                    <Grid item xs={6}>
                        <Labeled label="Tipo de factura">
                            <SelectField source="buyerData.invoiceType" choices={choices.invoicesTypes} {...all} />
                        </Labeled>
                    </Grid>
                    {invoiceType === INVOICE_TYPES.invoiceA ? (
                        <>
                            <Grid item xs={6}>
                                <Labeled label="CUIT">
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <TextField source="buyerData.cuit" {...all} />
                                        <CopyProductInformation
                                            data={cuit}
                                            formatText={(data) => `CUIT: ${data}`}
                                        />
                                    </Box>
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Razón social">
                                    <TextField source="buyerData.businessName" {...all} />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Condición frente al IVA">
                                    <SelectField source="buyerData.ivaCondition" choices={choices.ivaConditions} {...all} />
                                </Labeled>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={6}>
                                <Labeled label="DNI">
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <TextField source="buyerData.dni" {...all} />
                                        <CopyProductInformation
                                            data={dni}
                                            formatText={(data) => `DNI: ${data}`}
                                        />
                                    </Box>
                                </Labeled>
                            </Grid>
                        </>
                    )}

                    {invoiceType === INVOICE_TYPES.invoiceA ? (
                        <>
                            <Grid item xs={6}>
                                <Labeled label="Provincia">
                                    <TextField source="buyerData.province" {...all} />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Localidad">
                                    <TextField source="buyerData.locality" {...all} />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Domicilio comercial">
                                    <TextField source="buyerData.businessAddress" {...all} />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Piso/Depto">
                                    <TextField source="buyerData.floor" {...all} />
                                </Labeled>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={6}>
                                <Labeled label="Nombre">
                                    <TextField source="buyerData.firstName" {...all} />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Apellido">
                                    <TextField source="buyerData.lastName" {...all} />
                                </Labeled>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <ContactPhoneField {...all} />
                    </Box>
                    <Box>
                        <ContactEmailField email={user?.email} {...all} />
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Labeled label="Método de pago">
                            <SelectField source="paymentData.method" choices={choices.paymentMethods} {...all} />
                        </Labeled>
                    </Box>
                    <Box>
                        <Labeled label="Tipo de precio">
                            <FunctionField
                                render={(rec) => getPriceTypeLabel(rec)}
                                {...all}
                            />
                        </Labeled>
                    </Box>
                </Grid>
            </Grid>
            <Divider style={{ marginTop: 32, marginBottom: 16 }} />
            <DetailedProducts editable={editable} {...all} />
        </>
    );
};

export default DetailsTab;
